import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import {getUrlFromImage} from "../components/Gallery";

// eslint-disable-next-line
export const TuinarchitectuurPageTemplate = class extends React.Component {
  componentDidMount() {
    let root = document.documentElement;
    root.style.setProperty('--tp-navbar-color', '#fff');
  }
  render() {

  return (
      <div>
        <section className="header compact">
          <div className="header-image" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(${getUrlFromImage(this.props.titleImage)})`}}></div>

          <div className="container">
            <h1 className="title">TUINARCHITECTUUR</h1>
          </div>
        </section>
        <section className="content container mt-6 mb-6">
          <p>Van kleine stadstuin tot groot landschapsproject, met nodige zorg en kwaliteit aangepakt. Wij luisteren naar uw verwachtingen en komen zo tot het ideaal concept. Door het in 3D uit te tekenen heeft u direct een beeld hoe uw tuin er zal uitzien.</p>
        </section>
        <section className="content container mt-6 mb-6"> 
          <div className="row mt-5">
            <div className="col-lg-8">
              <h2>HOE GAAN <br/>WIJ TE WERK?</h2>
              <ol className="mt-5 mb-6">
                <li>Tijdens een verkennend gesprek en een bevestiging voor samenwerking wordt bestaande situatie in kaart gebracht.</li>
                <li>Alle afmetingen, hoogtematen als ook leuke en hinderlijke in -en uitzichten worden ter plaatse bekeken.</li>
                <li>Vervolgens worden er drie simpele bovenaanzichten getekend.</li>
                <li>Eens één van de drie in de smaak valt wordt er een 3D presentatie gemaakt van de tuin.</li>
                <li>Verplichte bedenk periode, zo kunnen we tot een perfect plaatje komen.</li>
                <li>Dit geheel wordt voorzien van de juiste materialen waaronder bestrating, beplanting,…</li>
                <li>Als laatste de aanleg zelf.</li>
              </ol>
            </div>
            <div className="col-lg-4 ps-5">
              <div className="foto-architectuur" style={{background: `url(${getUrlFromImage(this.props.architectuurImage)}) top center / cover no-repeat`}}></div>
            </div>
          </div>
        </section>
        <section className="content container mt-6 mb-6"> 
          <div className="row">
            {
              this.props.fotos.map(foto => {
                return (
                  <div className="col-md-4">
                    <div className="foto-project" style={{background: `url(${getUrlFromImage(foto.image)}) top center / cover no-repeat`}}>
                      {/* <span className="foto-text">{foto.text}</span> */}
                    </div>
                  </div>
                )
              })
            }
          </div>
        </section>
      </div>
      
    );
  }
}

TuinarchitectuurPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const TuinarchitectuurPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <TuinarchitectuurPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        fotos={post.frontmatter.fotosTuinarchitectuur}
        titleImage={post.frontmatter.titleImage}
        architectuurImage={post.frontmatter.architectuurImage}
      />
    </Layout>
  );
};

TuinarchitectuurPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TuinarchitectuurPage;

export const tuinarchitectuurPageQuery = graphql`
  query TuinarchitectuurPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        titleImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 60) {
              ...GatsbyImageSharpFluid
            }
            original {
              width
              height
            }
          }
        }
        architectuurImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 60) {
              ...GatsbyImageSharpFluid
            }
            original {
              width
              height
            }
          }
        }
        fotosTuinarchitectuur {
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 60) {
                ...GatsbyImageSharpFluid
              }
              original {
                width
                height
              }
            }
          }
          text
        }
      }
    }
  }
`;
